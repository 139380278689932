@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'VAGRoundedBT';
  src: local('VAGRoundedBT'), url(./fonts/VAGRoundedBT.ttf);
}

@font-face {
  font-family: 'AvenirLTStd';
  src: local('AvenirLTStd'), url(./fonts/AvenirLTStd-Roman.otf);
}

a {
	font-family: 'AvenirLTStd';
}
